import init_popup from './popup';
import axios from 'axios';

function user_vote() {
    init_popup();

    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common['X-CSRF-Token'] = token;
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    document.addEventListener('click', (event) => {
        let self = event.target;
        if (self.dataset.event === 'vote') {
            axios.post('/vote', {
                    id: self.dataset.id
                })
                .then((response) => {
                    let data = response.data;

                    if (data.show_popup) {
                        global.popup.show();
                    } else {
                        self.closest('.people-vote').innerHTML = `<div class="people-btn people-btn_value">${data.text}</div>`;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    });

    document.querySelectorAll('*[data-popup-action="popup-close"]').forEach((item) => {
        item.addEventListener('click', function(){
            global.popup.hide();
        })
    });

    let user_company = document.getElementById('company_title');
    let button = document.querySelector('.btnevt');

    if (user_company && button) {
        user_company.addEventListener('input', function() {
            let value = this.value;
            if (value.length >= 3) {
                button.disabled = false;
            } else {
                button.disabled = true;
            }

            axios.post('/save_company_name', {
                company: value
            })
            .then((response) => {
                // console.log(response);
                // empty
            })
            .catch((error) => {
                console.error(error);
            });
        });

        button.addEventListener('click', (event) => {
            window.location = event.target.dataset.url;
        });
    }
}

export default user_vote;
